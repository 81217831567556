.switch-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.switch-container input {
    display: none;
}

.slider {
    position: relative;
    width: 60px;
    height: 25px;
    background-color: #ccc;
    border-radius: 34px;
    display: flex;
    align-items: center;
    padding: 0 4px;
    /* Added padding to make space for the circle */
}

.label {
    margin: 0 2px;
    color: #ffffff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
}

.label.left {
    left: 8px;
    font-size: 0.875rem;
}

.label.right {
    right: 8px;
    font-size: 0.875rem;
}

.circle {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 5px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

.switch-container input:checked+.slider {
    background-color: #4caf50;
    /* Green background when switch is ON */
}

.switch-container input:not(:checked)+.slider {
    background-color: #ff0000;
    /* Red background when switch is OFF */
}

.switch-container input:checked+.slider .circle {
    left: calc(100% - 24px);
}